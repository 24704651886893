import React, { useState } from 'react';
import image1 from '../assets/Group 1.png';
import image2 from '../assets/Group 2.png';
import image3 from '../assets/Group 3.png';
import image4 from '../assets/Group 4.png';
import image5 from '../assets/Group 5.png';
import image6 from '../assets/Group 6.png';
import image7 from '../assets/Group 7.png';
import image8 from '../assets/Group 8.png';
import "../index.css";
import  tam from "../assets/sipka-right.svg";
import  back from "../assets/sipka-left.svg";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8
];

const Carousell = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className='w-screen bg-[#F8F8F8] py-36'>
      <div className="bg-[#F8F8F8] relative w-full max-w-[1230px] mx-auto pb-20 px-4">
        <p className='j-font4 text-4xl md:text-6xl text-gray-900 text-center pb-11'>Služby</p>
        <div className="overflow-hidden">
          <div
            className="flex transition-transform duration-500"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {images.map((src, index) => (
              <div key={index} className="flex-none px-8 w-full md:w-[400px]">
                <img
                  src={src}
                  alt={`Slide ${index}`}
                  className="w-full h-auto object-cover"
                />
              </div>
            ))}
          </div>
        </div>
        <button
          className="absolute top-1/2 left-1 md:left-[-100px] transform -translate-y-1/2 text-white p-2 md:p-4"
          onClick={prevImage}
        >
          <img src={back} className='w-10 h-[70px] md:w-28 md:h-28 car-z' />
        </button>
        <button
          className="absolute top-1/2 right-1 md:right-[-100px] transform -translate-y-1/2  text-white p-2 md:p-4"
          onClick={nextImage}
        >
          <img src={tam} className='w-10 h-[70px] md:w-28 md:h-28 car-t' />
        </button>
      </div>
    </div>
  );
}

export default Carousell;
